import type { RouteUpdateArgs } from 'gatsby';
import 'normalize.css';
import 'styles/scss/global.scss';
import { VOGLIO_FIELDS } from '@bondvet/types/voglio';

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
    const params = new URLSearchParams(location.search);

    // save voglio params to local storage as individual keys
    VOGLIO_FIELDS.forEach((key) => {
        const value = params.get(key);
        if (value) {
            // if we already have something in localStorage, don't overwrite it
            // we want to keep the original value in localStorage
            if (!localStorage.getItem(key)) {
                localStorage.setItem(key, value);
            }
            sessionStorage.setItem(key, value);
        }
    });

    if (window) {
        window.zESettings = {
            webWidget: {
                color: {
                    launcher: '#103559',
                    header: '#10365A',
                    resultLists: '#295889',
                    button: '#103559',
                    articleLinks: '#FF4D4D',
                },
            },
        };
    }

    if (location.pathname.includes('/booking')) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'hide');
    } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        window.zE && window.zE('webWidget', 'show');
    }

    const cookiebotWidget = document.getElementById('CookiebotWidget');
    if (cookiebotWidget) {
        if (location.pathname.includes('/admin')) {
            // Hide CookiebotWidget for CMS
            cookiebotWidget.style.display = 'none';
        } else {
            // Ensure CookiebotWidget is visible on other routes
            cookiebotWidget.style.display = '';
        }
    }

    function adjustHeader() {
        // deal with having both the adbar and unbounce scripts
        const adbarHeight = getComputedStyle(document.documentElement)
            .getPropertyValue('--adbar-height')
            .trim();
        const header = document.querySelector(
            '#page-header',
        ) as HTMLElement | null;

        if (header) {
            header.style.top = adbarHeight === '0px' ? 'auto' : adbarHeight;
        }
    }

    adjustHeader();
    window.addEventListener('resize', adjustHeader);

    return () => {
        window.removeEventListener('resize', adjustHeader);
    };
};

function ensureIntersectionObserverPolyfill() {
    const polyFillSrc =
        // eslint-disable-next-line max-len
        'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=IntersectionObserver';

    if (!document.querySelector(`script[src="${polyFillSrc}]"`)) {
        const script = document.createElement('script');
        script.setAttribute('defer', 'defer');
        script.setAttribute('src', polyFillSrc);

        document.head.appendChild(script);
    }
}

export function onClientEntry() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import('modernizr').then();
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!('IntersectionObserver' in window)) {
        ensureIntersectionObserverPolyfill();
    }
}

// automatically reload when the service worker has been updated
export const onServiceWorkerUpdateReady = () => {
    window.location.reload();
};
