import upperFirst from 'lodash/upperFirst';
import {
    BOOKING_STEPS,
    BookingActionTypes,
    BookingState,
    FAILED,
    FailedAction,
    FINISHED,
    FinishedAction,
    SELECT_CITY,
    SELECT_DATE,
    SELECT_LOCATION,
    SELECT_PROVIDER,
    SELECT_TIME,
    SET_NEARBY_AVAILABILITY,
    SelectCityAction,
    SelectDateAction,
    SelectLocationAction,
    SelectProviderAction,
    SelectTimeAction,
    SetNearbyAvailabilityAction,
    SET_AGE,
    SET_AGE_UNIT,
    SET_ANIMAL_ID,
    SET_BIRTHDATE,
    SET_BOOTED,
    SET_BREED,
    SET_CLIENT_ID,
    SET_CLIENT_REQUIRES_CREDIT_CARD,
    SET_PROMO_CODE,
    SET_EMAIL,
    SET_FIRST_NAME,
    SET_HAS_EXISTING_CC_DETAILS,
    SET_IS_NEW_CLIENT,
    SET_KNOWN_PATIENTS,
    SET_KNOWS_EXACT_BIRTHDATE,
    SET_LAST4,
    SET_LAST_NAME,
    SET_LOADING_CLIENT_REQUIRES_CREDIT_CARD,
    SET_LOADING_EXISTING_CC_DETAILS,
    SET_NOTES,
    SET_PET,
    SET_PHONE_NUMBER,
    SET_REASON,
    SET_REPRODUCTIVE_HEALTH,
    SET_SESSION_ID,
    SET_SETUP_INTENT_ID,
    SET_SEX,
    SET_SMS_PREFERENCES,
    SET_SMS_PREFERENCES_DIRTY,
    SET_SPECIES,
    SET_STEP_VALIDATED,
    SET_STEPS,
    SetAgeAction,
    SetAgeUnitAction,
    SetAnimalIdAction,
    SetBirthdateAction,
    SetBootedAction,
    SetBreedAction,
    SetClientIdAction,
    SetClientRequiresCreditCardAction,
    SetPromoCodeAction,
    SetEmailAction,
    SetFirstNameAction,
    SetHasExistingCCDetailsAction,
    SetIsNewClientAction,
    SetKnownPatientsAction,
    SetKnowsExactBirthdateAction,
    SetLast4Action,
    SetLastNameAction,
    SetLoadingClientRequiresCreditCardAction,
    SetLoadingExistingCCDetailsAction,
    SetNotesAction,
    SetPetAction,
    SetPhoneNumberAction,
    SetReasonAction,
    SetReproductiveHealthAction,
    SetSessionIdAction,
    SetSetupIntentIdAction,
    SetSexAction,
    SetSmsPreferencesAction,
    SetSmsPreferencesDirtyAction,
    SetSpeciesAction,
    SetStepsAction,
    SetStepValidatedAction,
    SUBMIT,
    SET_DIGITAL_WALK_IN,
    SetDigitalWalkInAction,
    FINISHED_DIGITAL_WALK_IN,
    FinishedDigitalWalkInAction,
    CLEAR_ERRORS,
} from './types';

const initialState: BookingState = {
    booted: false,
    steps: BOOKING_STEPS.slice(0),
    sessionId: undefined,
    validatedSteps: new Set(),
    submitting: false,
    species: undefined,
    breed: '',
    knowsExactBirthdate: false,
    birthdate: null,
    age: undefined,
    ageUnit: 'years',
    sex: undefined,
    reproductiveHealth: undefined,
    pet: undefined,
    cityId: '',
    locationId: '',
    provider: null,
    date: '',
    time: '',
    isNearbyAvailableSelected: false,
    isDigitalWalkIn: false,
    reason: '',
    notes: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    promoCode: '',
    hasExistingCCDetails: false,
    isLoadingExistingCCDetails: false,
    setupIntentId: null,
    appointment: null,
    error: null,
    digitalWalkIn: null,
    isNewClient: undefined,
    knownPatients: [],
    clientId: '',
    animalId: '',
    last4: '',
    smsPreferences: false,
    smsPreferencesDirty: false,
    clientRequiresCreditCard: false,
    isLoadingClientRequiresCreditCard: false,
};

export default function bookingReducer(
    // eslint-disable-next-line @typescript-eslint/default-param-last
    state: BookingState = initialState,
    { type, ...action }: BookingActionTypes,
): BookingState {
    switch (type) {
        case SET_BOOTED:
            return {
                ...state,
                booted: (action as SetBootedAction).payload.booted,
            };
        case SET_PET:
            return {
                ...state,
                pet: (action as SetPetAction).payload.pet
                    .split(' ')
                    .map((word) => upperFirst(word))
                    .join(' '),
            };
        case SET_SPECIES:
            return {
                ...state,
                species: (action as SetSpeciesAction).payload.species,
            };
        case SET_BREED:
            return {
                ...state,
                breed: (action as SetBreedAction).payload.breed,
            };
        case SET_KNOWS_EXACT_BIRTHDATE:
            return {
                ...state,
                knowsExactBirthdate: (action as SetKnowsExactBirthdateAction)
                    .payload.knowsExactBirthdate,
            };
        case SET_BIRTHDATE:
            return {
                ...state,
                birthdate: (action as SetBirthdateAction).payload.birthdate,
            };
        case SET_AGE:
            return {
                ...state,
                age: (action as SetAgeAction).payload.age,
            };
        case SET_AGE_UNIT:
            return {
                ...state,
                ageUnit: (action as SetAgeUnitAction).payload.ageUnit,
            };
        case SET_SEX:
            return {
                ...state,
                sex: (action as SetSexAction).payload.sex,
            };
        case SET_REPRODUCTIVE_HEALTH:
            return {
                ...state,
                reproductiveHealth: (action as SetReproductiveHealthAction)
                    .payload.reproductiveHealth,
            };
        case SELECT_CITY:
            return {
                ...state,
                cityId: (action as SelectCityAction).payload.cityId,
            };
        case SELECT_LOCATION:
            return {
                ...state,
                locationId: (action as SelectLocationAction).payload.locationId,
            };
        case SELECT_PROVIDER:
            return {
                ...state,
                provider: (action as SelectProviderAction).payload.provider,
            };
        case SELECT_DATE:
            return {
                ...state,
                date: (action as SelectDateAction).payload.date,
            };
        case SELECT_TIME:
            return {
                ...state,
                time: (action as SelectTimeAction).payload.time,
            };
        case SET_NEARBY_AVAILABILITY:
            return {
                ...state,
                isNearbyAvailableSelected: (
                    action as SetNearbyAvailabilityAction
                ).payload.isNearbyAvailableSelected,
            };
        case SET_DIGITAL_WALK_IN:
            return {
                ...state,
                isDigitalWalkIn: (action as SetDigitalWalkInAction).payload
                    .isDigitalWalkIn,
            };
        case SET_REASON:
            return {
                ...state,
                reason: (action as SetReasonAction).payload.reason,
            };
        case SET_NOTES:
            return {
                ...state,
                notes: (action as SetNotesAction).payload.notes,
            };
        case SET_FIRST_NAME:
            return {
                ...state,
                firstName: (action as SetFirstNameAction).payload.firstName,
            };
        case SET_LAST_NAME:
            return {
                ...state,
                lastName: (action as SetLastNameAction).payload.lastName,
            };
        case SET_EMAIL:
            return {
                ...state,
                email: (action as SetEmailAction).payload.email,
            };
        case SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: (action as SetPhoneNumberAction).payload
                    .phoneNumber,
            };
        case SET_SMS_PREFERENCES:
            return {
                ...state,
                smsPreferences: (action as SetSmsPreferencesAction).payload
                    .smsPreferences,
            };
        case SET_SMS_PREFERENCES_DIRTY:
            return {
                ...state,
                smsPreferencesDirty: (action as SetSmsPreferencesDirtyAction)
                    .payload.smsPreferencesDirty,
            };
        case SET_PROMO_CODE:
            return {
                ...state,
                promoCode: (
                    action as SetPromoCodeAction
                ).payload.promoCode?.toUpperCase(),
            };
        case SET_LOADING_EXISTING_CC_DETAILS: {
            const { isLoading } = (action as SetLoadingExistingCCDetailsAction)
                .payload;
            return {
                ...state,
                isLoadingExistingCCDetails: isLoading,
                hasExistingCCDetails: isLoading
                    ? false
                    : state.hasExistingCCDetails,
            };
        }
        case SET_HAS_EXISTING_CC_DETAILS: {
            const { hasExistingCCDetails } = (
                action as SetHasExistingCCDetailsAction
            ).payload;
            return {
                ...state,
                hasExistingCCDetails,
            };
        }
        case SET_IS_NEW_CLIENT: {
            const { isNewClient } = (action as SetIsNewClientAction).payload;
            return {
                ...state,
                isNewClient,
            };
        }
        case SET_KNOWN_PATIENTS: {
            const { knownPatients } = (action as SetKnownPatientsAction)
                .payload;
            return {
                ...state,
                knownPatients,
            };
        }
        case SET_CLIENT_ID: {
            const { clientId } = (action as SetClientIdAction).payload;
            return {
                ...state,
                clientId,
            };
        }
        case SET_SESSION_ID: {
            return {
                ...state,
                sessionId: (action as SetSessionIdAction).payload.sessionId,
            };
        }
        case SET_ANIMAL_ID: {
            const { animalId } = (action as SetAnimalIdAction).payload;
            return {
                ...state,
                animalId,
            };
        }
        case SET_LAST4: {
            const { last4 } = (action as SetLast4Action).payload;
            return {
                ...state,
                last4,
            };
        }
        case SET_SETUP_INTENT_ID:
            return {
                ...state,
                setupIntentId: (action as SetSetupIntentIdAction).payload
                    .setupIntentId,
            };
        case SET_STEPS: {
            return {
                ...state,
                steps: (action as SetStepsAction).payload.steps.slice(0),
            };
        }
        case SET_STEP_VALIDATED: {
            const payload = (action as SetStepValidatedAction).payload;
            const newSet = new Set(state.validatedSteps);

            if (payload.isValidated) {
                newSet.add(payload.id);
            } else {
                newSet.delete(payload.id);
            }

            const newState = {
                ...state,
                validatedSteps: newSet,
            };

            return newState;
        }
        case SUBMIT:
            return {
                ...state,
                submitting: true,
                error: null,
            };
        case FINISHED:
            return {
                ...state,
                submitting: false,
                appointment: (action as FinishedAction).payload.appointment,
            };
        case FINISHED_DIGITAL_WALK_IN:
            return {
                ...state,
                submitting: false,
                digitalWalkIn: (action as FinishedDigitalWalkInAction).payload
                    .digitalWalkIn,
            };
        case FAILED: {
            return {
                ...state,
                submitting: false,
                error: (action as FailedAction).payload.error,
            };
        }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case SET_LOADING_CLIENT_REQUIRES_CREDIT_CARD: {
            const { isLoading } = (
                action as SetLoadingClientRequiresCreditCardAction
            ).payload;
            return {
                ...state,
                isLoadingClientRequiresCreditCard: isLoading,
                clientRequiresCreditCard: isLoading
                    ? false
                    : state.clientRequiresCreditCard,
            };
        }
        case SET_CLIENT_REQUIRES_CREDIT_CARD: {
            const { clientRequiresCreditCard } = (
                action as SetClientRequiresCreditCardAction
            ).payload;
            return {
                ...state,
                clientRequiresCreditCard,
            };
        }
        default:
            return state;
    }
}
