import React from 'react';

interface ABTestingContextType {
    getPageVariation: () => string | null;
    getAllPageVariations: () => string[] | null;
}

const ABTestingContext = React.createContext<ABTestingContextType>({
    getPageVariation: () => null,
    getAllPageVariations: () => null,
});

/**
 * To add a new hardcoded experiment on Convert, set it up like this:
 * window.usePageVariation = {...window.usePageVariation, '{newUrl}': '{newExperimentKey}'};
 *
 * Replace [url] with the URL for the experiment and 'variationKey' with the specific variation.
 * For example:
 * window.usePageVariation = {...window.usePageVariation, '/booking/date': 'providerDTest2'};
 *
 * Two experiments can't run on the same page this way.
 *
 * @param children
 * @constructor
 */
export const ABTestingProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [pageVariations, setPageVariations] = React.useState<string[]>([]);

    const getPageVariation = (): string | null => {
        if (typeof window !== 'undefined' && window.location) {
            const currentUrl = window.location.pathname;
            const experimentConfig = window.usePageVariation;
            return experimentConfig?.[currentUrl] || null;
        }
        return null;
    };

    const getAllPageVariations = (): string[] | null => {
        return pageVariations.length > 0 ? pageVariations : null;
    };

    // add mutation observer for changes to usePageVariation
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const updateVariations = () => {
                const newVariations = window.usePageVariation
                    ? Object.values(window.usePageVariation)
                    : [];
                if (
                    JSON.stringify(newVariations) !==
                    JSON.stringify(pageVariations)
                ) {
                    setPageVariations(newVariations);
                }
            };
            updateVariations();

            const observer = new MutationObserver(() => updateVariations());
            observer.observe(document.documentElement, {
                childList: true,
                subtree: true,
            });

            return () => observer.disconnect();
        }
        return undefined;
    }, [pageVariations]);

    return (
        <ABTestingContext.Provider
            value={{ getPageVariation, getAllPageVariations }}
        >
            {children}
        </ABTestingContext.Provider>
    );
};

export const useABTesting = (): ABTestingContextType => {
    const context = React.useContext(ABTestingContext);
    if (!context) {
        console.error('useABTesting must be used within an ABTestingProvider');
    }
    return context;
};
