import * as React from 'react';
import CookiebotContext, { CookieConsentState } from './context';

export default function CookiebotProvider({
    children,
}: React.PropsWithChildren<unknown>): React.ReactElement {
    const [cookieConsent, setCookieConsent] =
        React.useState<CookieConsentState>({
            necessary: false,
            preferences: false,
            statistics: false,
            marketing: false,
        });

    // Use a ref to store the current consent state
    const cookieConsentRef = React.useRef<CookieConsentState>(cookieConsent);

    React.useEffect(() => {
        cookieConsentRef.current = cookieConsent;
    }, [cookieConsent]);

    // Partytown requires the scripts to have "text/partytown" as type
    // Cookiebot requires them to have "text/plain" as type
    // After Cookiebot updates them, we need to set them to the Partytown type to fetch them
    const updatePartytownScripts = React.useCallback(() => {
        // Only process scripts marked for Partytown
        const scripts = document.querySelectorAll(
            '[data-partytown="true"]:not([data-processed="true"])',
        );

        scripts.forEach((script) => {
            try {
                const cookieType = script.getAttribute(
                    'data-cookieconsent',
                ) as keyof CookieConsentState;
                const isAllowed =
                    cookieType && cookieConsentRef.current[cookieType];

                if (!script || typeof script.setAttribute !== 'function') {
                    console.warn('Invalid script detected. Skipping:', script);
                    return;
                }

                if (isAllowed) {
                    if (!script.hasAttribute('data-initialized')) {
                        script.setAttribute('type', 'text/partytown');
                        script.setAttribute('data-processed', 'true'); // Mark as processed
                        script.setAttribute('data-initialized', 'true');
                        window.dispatchEvent(new CustomEvent('ptupdate'));
                    }
                }
            } catch (error) {
                console.error('Error updating Partytown script:', {
                    script,
                    error,
                });
            }
        });
    }, [cookieConsentRef]);

    // See "runScripts" on the documentation: https://www.cookiebot.com/en/developer/
    // When content gets added after the consent had been loaded, it only gets updated
    // with a call to Cookiebot.runScripts.
    // Scripts are only executed once, so this function is safe to call several times.
    // If the scripts aren't ran in order, a race condition could occur.
    const runScriptsInOrder = React.useCallback(async () => {
        if (
            typeof window.Cookiebot !== 'undefined' &&
            typeof window.Cookiebot.runScripts === 'function'
        ) {
            // Add delay to avoid race condition
            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    window.Cookiebot?.runScripts();
                    resolve();
                }, 300);
            });

            updatePartytownScripts();
        }
    }, [updatePartytownScripts]);

    React.useEffect(() => {
        const handleConsentChange = () => {
            // Helper function to get the latest consent values
            const getConsentState = (): CookieConsentState => {
                if (typeof window.Cookiebot !== 'undefined') {
                    const consent = window.Cookiebot.consent || {};
                    return {
                        necessary: consent.necessary || false,
                        preferences: consent.preferences || false,
                        statistics: consent.statistics || false,
                        marketing: consent.marketing || false,
                    };
                }
                return {
                    necessary: false,
                    preferences: false,
                    statistics: false,
                    marketing: false,
                };
            };

            setCookieConsent(getConsentState());
            runScriptsInOrder();
        };

        // Listen for Cookiebot events
        if (typeof window !== 'undefined') {
            window.addEventListener(
                'CookiebotOnConsentChange',
                handleConsentChange,
            );
            window.addEventListener(
                'CookiebotOnConsentReady',
                handleConsentChange,
            );

            // If Cookiebot is already initialized, immediately retrieve the consent state
            if (window.Cookiebot && window.Cookiebot.consent) {
                handleConsentChange();
            }
        }

        // Cleanup listeners on unmount
        return () => {
            window.removeEventListener(
                'CookiebotOnConsentChange',
                handleConsentChange,
            );
            window.removeEventListener(
                'CookiebotOnConsentReady',
                handleConsentChange,
            );
        };
    }, [runScriptsInOrder]);

    React.useEffect(() => {
        let mutationTimeout: NodeJS.Timeout | null = null;

        const observer = new MutationObserver(() => {
            if (mutationTimeout) clearTimeout(mutationTimeout);
            mutationTimeout = setTimeout(() => {
                runScriptsInOrder();
            }, 200);
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            if (mutationTimeout) clearTimeout(mutationTimeout);
            observer.disconnect();
        };
    }, [runScriptsInOrder]);

    return (
        <CookiebotContext.Provider
            value={{
                cookieConsent,
                runCookiebotScripts: runScriptsInOrder,
                updatePartytownScripts,
            }}
        >
            {children}
        </CookiebotContext.Provider>
    );
}

export const useCookiebot = (): CookieConsentState => {
    const context = React.useContext(CookiebotContext);

    if (!context) {
        throw new Error('useCookiebot must be used within a CookiebotProvider');
    }

    return context.cookieConsent;
};
