import * as React from 'react';
import type { Maybe, Settings } from '@types/gatsby-types';
import { graphql, ScriptStrategy, useStaticQuery } from 'gatsby';
import Script from 'analytics/components/Script';

const query = graphql`
    query AccessiBeQuery {
        settings {
            accessibe {
                enabled
            }
        }
    }
`;

interface AccessiBeQuery {
    settings: Maybe<Pick<Settings, 'accessibe'>>;
}

export default function AccessiBe() {
    const { settings } = useStaticQuery<AccessiBeQuery>(query);

    const accessibeEnabled = settings?.accessibe?.enabled;

    if (accessibeEnabled) {
        return (
            <>
                <Script
                    strategy={ScriptStrategy.idle}
                    cookieType="necessary"
                    id="accessibe"
                    content={`(function(d){
                          var s = d.createElement("script");
                          s.setAttribute("data-trigger", "accessibilityWidget");
                          s.setAttribute("data-color", "#11355A");
                          s.setAttribute("data-account", "qBOl4l4mHZ");
                          s.setAttribute("src", "https://cdn.userway.org/widget.js");
                          s.setAttribute("data-position", "5");
                          (d.body || d.head).appendChild(s);
                        })(document)`}
                />
                <div id="accessibilityWidget" />
            </>
        );
    }

    return null;
}
